export const locale = {
    lang: 'fr',
    data: {
        trackSideBar: {
            entries: 'Entrées',
            exits: 'Sorties',
            noEntriesExits: 'Vous n\'avez pas d\'entrées-sorties pour le ',
            selectAnotherDay: 'Veuillez sélectionner une autre date.'

        }
    },
};
