export const locale = {
    lang: 'dz',
    data: {
        note: "ملاحظة :",
        messages: {
            deleteFolder: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
            deleteEstablishment: 'هل أنت متأكد أنك تريد حذف هذه المؤسسة؟',
            deleteLevel: 'هل أنت متأكد أنك تريد حذف هذا المستوى؟',
            deleteLevelInstance: 'هل أنت متأكد أنك تريد حذف هذه الهيئة ؟',
            deleteRank: 'هل أنت متأكد أنك تريد حذف هذا المستوى؟',
            deletesalary: 'هل أنت متأكد أنك تريد حذف مستوى الراتب هذا؟',
            deleteClientRubric: 'هل أنت متأكد أنك تريد حذف هذا القسم؟',
            disabledClientRubric: 'هل أنت متأكد أنك تريد إلغاء تفعيل هذه القسم؟',
            confAddToPlan: 'حذاري ! ستتم إضافة جميع العناوين الفرعية المجاورة تلقائيًا إلى الخطة',
            deleteLeaves: 'هل أنت متأكد أنك تريد حذف هذه الإجازة؟',
            deleteEmployeeRubric: 'هل أنت متأكد أنك تريد حذف هذا القسم من العامل؟',
            deletePlanRubric: 'هل أنت متأكد أنك تريد حذف هذه القسم؟',
            deleteGroupRubric: 'هل أنت متأكد أنك تريد حذف هذه المجموعة؟',
            deleteRubricInGroup: 'هل أنت متأكد أنك تريد حذف هذه القسم من المجموعة؟',
            manualyPointingDays: 'عدد الأيام المسجلة اكبر من عدد الايام المعمولة ، هل تريد المتابعة؟',
            deletePayroll: 'هل أنت متأكد أنك تريد حذف هده المجموعة',
            deleteEmployeePay: 'هل أنت متأكد أنك تريد حذف هذا القسم',

        },
        buttons: {
            ok: 'نعم',
            no: 'لا',
            cancel: 'إلغاء',
            conf: 'تأكيد',
        }
    },
};
