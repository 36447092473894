export const locale = {
    lang: 'fr',
    data: {
        dataTable: {
            actions: {
                view: 'Visualiser',
                cancel: 'Annuler',
                edit: 'Modifier',
                delete: 'Supprimer'
            },
            columns: {
                establishment: 'Établissement',
                exitDate: 'Date et heure',
                startDateToEndDtate: 'Date de début-fin',
                justified: 'Justifiée',
                status: 'Statut',
                reason: 'Motif',
                exercices: 'Exercices',
                accumulated: 'Cumulé',
                period: 'Période',
                taken: 'Pris',
                remaining: 'Acquis',
                refPeriod: 'Période de référence',
                consommed: 'Consommé',
                remaining2: 'Restant',
                entries: 'Entrée',
                exits: 'Sorties',
                nbrHours: 'Nombre d\'heures',
                duration: 'Durée',
                loanDate: 'Date du prêt',
                amount: 'Montant',
                monthlyPayment: 'Mensualité',
                refunded: 'Remboursé',
                refundStatus: 'État du remboursement',
                remairemainingDurationning: 'Durée restante',
                refundState2: 'État',
                productName: 'Nom du produit',
                purchaseDate: 'Date',
                object:'Objet de la Mission',
                object2:'Objet',
                location:'Lieu',
                description:'Descriptions'
            }
        }

    }
};
