<div class="popup-container p-20">

    <div class="content">
        <div class="icon">
            <mat-icon *ngIf="isWarning"
                style="color:#ff9a00bd; font-size: 45px; margin-bottom:5px 0  20px 0; height: 50px; width: 50px;">{{
                icon ??
                'warning'}}</mat-icon>
        </div>
        <p class="msg">
            {{ msg }}
        </p>
    </div>
    <div class="button-div">
        <button mat-button *ngFor="let option of options" (click)="action(option.value)">{{option.text}}</button>
    </div>
</div>