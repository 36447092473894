export const locale = {
    lang: 'dz',
    data: {
        dataTable: {
            actions: {
                view: 'عرض',
                cancel: 'الغاء',
                edit: 'تعديل',
                delete: 'حدف'
            },
            columns: {
                establishment: 'مؤسسة',
                exitDate: 'التاريخ و الساعة',
                startDateToEndDtate: 'تاريخ البدية-النهاية',
                justified: 'مبرر',
                status: 'الحالة',
                reason: 'السبب',
                exercices: 'Exercices',
                accumulated: 'متركم',
                period: 'الفترة',
                taken: 'قرض',
                remaining: 'مكتسب',
                refPeriod: 'فترة مرجعية',
                consommed: 'مستهلك',
                remaining2: 'المتبقي',
                entries: 'مدخل',
                exits: 'خرجات',
                nbrHours: 'عدد الساعات',
                duration: 'المدة',
                loanDate: 'تاريخ القرض',
                amount: 'المبلغ',
                monthlyPayment: 'الدفع الشهري',
                refunded: 'معوض',
                refundStatus: 'حالة التعويض',
                remairemainingDurationning: 'المدة المتبقية',
                refundState2: 'الحالة',
                productName: 'اسم المنتوج',
                purchaseDate: 'تاريخ',
                object:'Objet de la Mission',
                object2:'Objet',
                location:'Lieu',
                description:'Descriptions'


            }
        }

    }
};
