import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as fr } from './i18n/fr';
import { locale as dz } from './i18n/dz';
@Component({
    selector: 'app-popup-sirh',
    templateUrl: './popup-sirh.component.html',
    styleUrls: ['./popup-sirh.component.scss']
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PopupSirh implements OnInit {
    options: any[];
    msg: string;
    isWarning = false;
    showTitle = false;
    hasMonthDate = false;
    month: any;
    icon: any;
    constructor(
        private fuseTranslationLoaderService: FuseTranslationLoaderService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public currentDialog: MatDialogRef<PopupSirh>,
        private translateService: TranslateService,
        private fuseTranslationLoader: FuseTranslationLoaderService
    ) {
        this.options = this.data.options;
        this.msg = this.data.message;
        this.isWarning = this.data.isWarning;
        this.showTitle = this.data.showTitle;
        this.hasMonthDate = this.data.hasMonthDate;
        this.month = this.data.month;
        this.icon = this.data.icon;
    }

    ngOnInit() {
        this.fuseTranslationLoader.loadTranslations(fr, dz);
    }

    action(value) {
        this.currentDialog.close(value);
    }

}
