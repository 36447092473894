export const locale = {
    lang: 'dz',
    data: {
        trackSideBar: {
            entries: 'مداخل',
            exits: 'مخارج',
            noEntriesExits: 'ليس لديك أي مداخل/مخارج ل ',
            selectAnotherDay: 'الرجاء تحديد تاريخ آخر.'

        }
    },
};
